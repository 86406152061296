<template>
  <div>
    <b-card no-body >
      <b-card-header class="pb-50">
        <h5>
          筛选
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <v-select
              v-model="searchProductId"
              :options="productList"
              placeholder="请选择所属产品"
              label="name"
              :reduce="option => option.id"
            >
              <template slot="no-options">{{$t('noData')}}</template>
            </v-select>
          </b-col>
          <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 "
          >
            <div
              class="d-flex justify-content-center"
            >
              <b-badge
                variant="primary"
              >
                全部设备
              </b-badge>
            </div>
            <div class="text-primary d-flex justify-content-center font-medium-2 x-link mt-sm-1 mb-sm-1">{{allCount}}</div>
          </b-col>
          <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
          >
            <div
            class="d-flex justify-content-center"
            >
              <b-badge
                variant="success"
              >
                在线
              </b-badge>
            </div>
            <div
            class="text-success d-flex justify-content-center font-medium-2 x-link mt-sm-1 mb-sm-1"
            @click="searchStatus = 'online'"
            >
              {{onlineCount}}
            </div>
          </b-col>
          <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
          >
            <div
              class="d-flex justify-content-center"
            >
            <b-badge variant="danger">
              离线
            </b-badge>
            </div>
            <div
              class="text-danger d-flex justify-content-center font-medium-2 x-link mt-sm-1 mb-sm-1"
              @click="searchStatus = 'offline'"
            >
              {{offLineCount}}
            </div>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <div
              class="d-flex justify-content-center"
            >
            <b-badge
              variant="warning"
            >
              未启用
            </b-badge>
            </div>
            <div
              class="text-warning d-flex justify-content-center font-medium-2 x-link mt-sm-1 mb-sm-1"
              @click="searchStatus = 'notActive'"
            >
              {{notActiveCount}}
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <x-table
      title="设备列表"
      ref="table"
      :card="true"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @rowDisable="rowDisable"
      @rowEnable="rowEnable">
      <template slot="actions">
        <div>
          <x-popconfirm
            target="active-btn"
            content="将激活全部设备，是否确认？"
            triggers="click"
            @enter="activeAllDevice">
          </x-popconfirm>
          <div id="active-btn" class="btn btn-light-danger mr-1 ml-1">
            <feather-icon icon="LinkIcon" class="mr-50"></feather-icon>
            激活全部
          </div>
          <x-popconfirm
            target="sync-state-btn"
            content="同步所有设备真实状态，是否确认？"
            triggers="click"
            @enter="syncAllDeviceState">
          </x-popconfirm>
          <div id="sync-state-btn" class="btn btn-light-danger">
            <feather-icon icon="RefreshCwIcon" class="mr-50"></feather-icon>
            同步设备状态
          </div>
        </div>
      </template>
    </x-table>
  </div>
</template>

<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BCard, BRow, BCol, BCardHeader, BCardBody, VBToggle, BBadge,
} from 'bootstrap-vue'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import vSelect from 'vue-select'
import {
  query, deploy, unDeploy, remove, queryProductList, add, edit, queryStateCount, syncState, activeAll,
} from '@/api/device-manage/device-list'

export default {
  components: {
    XTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    vSelect,
    BBadge,
    XPopconfirm,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        normalAddBtn: true,
        // formWidth: 'lg',
        actions: [
          { action: this.$x.biz.FormType.VIEW, name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: this.$x.biz.FormType.EDIT, name: '编辑', icon: 'EditIcon' },
          { action: this.$x.biz.FormType.DELETE, name: '删除', icon: 'Trash2Icon' },
          { action: 'disable', name: '禁用', icon: 'SlashIcon' },
          { action: 'enable', name: '启用', icon: 'RepeatIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.state.value === 'notActive') {
            return action === this.$x.biz.FormType.VIEW || action === this.$x.biz.FormType.EDIT || action === 'delete' || action === 'enable'
          }
          return action === this.$x.biz.FormType.VIEW || action === this.$x.biz.FormType.EDIT || action === 'disable'
        },
        columns: [{
          label: 'ID',
          labelShow: true,
          prop: 'id',
          editDisable: true,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入标识',
          },
        }, {
          label: '设备名称',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          linkUrl: '/device-center/device/view/{id}',
          rules: {
            rule: 'required',
            message: '请输入设备名称',
          },
        }, {
          label: '产品',
          labelShow: true,
          prop: 'productId',
          linkUrl: '/device-center/products/view/{productId}',
          type: 'select',
          editDisable: true,
          dictUrl: 'device-product/_query/no-paging?paging=false',
          props: { label: 'name', value: 'id' },
          getDictLabel: option => option.label + `（${option.value})`,
          onDictSelected: (option, column, formData) => {
            column[2].dictData.forEach(product => {
              if (option === product.value) {
                formData.productName = product.label
              }
            })
          },
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请选择产品',
          },
        }, {
          label: '产品名称',
          labelShow: true,
          prop: 'productName',
          editShow: false,
          addShow: false,
          viewShow: false,
          rowShow: false,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入产品名称',
          },
        }, {
          label: '所属机构',
          prop: 'orgId',
          type: 'select',
          dictUrl: '/organization/_all',
          props: { label: 'name', value: 'id' },
          rowShow: false,
          sortable: true,
          searchShow: false,
        }, {
          label: '注册时间',
          labelShow: true,
          prop: 'registryTime',
          type: 'datetime',
          editShow: false,
          addShow: false,
          viewShow: false,
          searchShow: false,
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          dictData: [{ value: 'notActive', text: '未启用' }, { value: 'online', text: '在线' }, { value: 'offline', text: '离线' }],
          selectVariant: prop => {
            if (prop.value === 'notActive') {
              return 'text-warning'
            }
            if (prop.value === 'online') {
              return 'text-success'
            }
            return 'text-danger'
          },
          props: { label: 'text', value: 'value' },
          editShow: false,
          addShow: false,
          viewShow: false,
        }, {
          label: '描述',
          labelShow: true,
          prop: 'describe',
          type: 'textarea',
          rows: 5,
          searchShow: false,
          rowShow: false,
        },
        ],
      },
      isNew: true,
      productList: [],
      searchProductId: this.$route.params.productId || this.$route.query.productId,
      allCount: 0,
      notActiveCount: 0,
      offLineCount: 0,
      onlineCount: 0,
    }
  },
  watch: {
    searchProductId() {
      this.$refs.table.__searchHandle()
    },
  },
  created() {
    queryProductList().then(resp => {
      this.productList = resp.data.result
    })
  },
  methods: {
    addHandle(formData, done) {
      add(formData).then(() => {
        done()
        this.$router.push({
          name: 'ec-device-view',
          params: { id: formData.id },
        })
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    // 设备启用
    rowEnable(data, done) {
      deploy(data.id).then(() => {
        done()
      })
    },
    // 设备禁用
    rowDisable(data, done) {
      unDeploy(data.id).then(() => {
        done()
      })
    },
    // 删除设备
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    toProduct(row) {
      this.$router.push({
        name: 'ec-product-view',
        params: { id: row.item.productId },
      })
    },
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params, this.searchProductId).then(resp => {
        this.allCount = resp.data.result.total
        done(resp.data.result.data, resp.data.result.total)
        const stateArr = [this.$x.biz.DevState.NOT_ACTIVE, this.$x.biz.DevState.ONLINE, this.$x.biz.DevState.OFFLINE]
        stateArr.forEach(item => {
          queryStateCount(item, this.searchProductId).then(res => {
            if (item === this.$x.biz.DevState.NOT_ACTIVE) {
              this.notActiveCount = res.data.result
            } else if (item === this.$x.biz.DevState.ONLINE) {
              this.onlineCount = res.data.result
            } else if (item === this.$x.biz.DevState.OFFLINE) {
              this.offLineCount = res.data.result
            }
          })
        })
      })
    },
    activeAllDevice() {
      activeAll().then(resp => {
        console.log(resp)
        this.$xtoast.success('操作成功！')
        this.$refs.table.__searchHandle()
      })
    },
    syncAllDeviceState() {
      syncState().then(resp => {
        console.log(resp)
        this.$xtoast.success('操作成功！')
        this.$refs.table.__searchHandle()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
